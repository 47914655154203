* {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	width: 100%;
	height: 100%;
}

body {
	background: #eee;
	display: flex;
	justify-content: center;
	align-items: center;
}

header {
	display: flex;
	padding: 1rem;
	justify-content: space-between;
}
